import React, { useState, useRef, useEffect, useContext } from "react";
import { Message, Close as CloseIcon } from "@mui/icons-material";
import ChatDialog from "./ChatDialogue";
import { encryptSession, GetCratoChat } from "./api";
import { subMonths } from "date-fns";
import { AppContext } from "./AppContext";

const SenderID = encryptSession.getItem("us");

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

const FloatingButton = () => {
  const { context, setContext } = useContext(AppContext);
  const buttonRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [reload, setReload] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogPosition, setDialogPosition] = useState({ top: 0, left: 0 });
  const [icon, setIcon] = useState(<Message />); // Initial icon
  const [unreadCount, setUnreadCount] = useState(0);
  const [dateRange, setDateRange] = useState({
    start: subMonths(new Date(), 3),
    end: new Date(),
  });
  const { data } = GetCratoChat(
    {
      DateRange: [dateRange?.start, dateRange?.end],
      UserID: SenderID,
    },
    reload
  );

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    setReload(!reload);
    try {
      const newUnreadCount =
        data?.filter(
          (message) => message.StatusID === 1 && message?.SenderID !== SenderID
        ).length || 0;
      if (newUnreadCount > unreadCount) {
        setUnreadCount(newUnreadCount);
        if (Notification.permission === "granted") {
          const notification = new Notification("New Unread Messages", {
            body: `You have ${newUnreadCount} new unread messages.`,
            icon: "/path/to/notification-icon.png", // Optional icon
          });

          // Add event listener to the notification click
          notification.onclick = () => {
            handleOpenDialog();
          };
        }
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  useInterval(
    () => {
      fetchData();
    },
    isDialogOpen ? 60000 : 300000
  );

  const startDrag = (e) => {
    setIsDragging(true);
    setOffset({
      x: e.clientX - buttonRef.current.getBoundingClientRect().left,
      y: e.clientY - buttonRef.current.getBoundingClientRect().top,
    });
  };

  const onDrag = (e) => {
    if (isDragging) {
      buttonRef.current.style.left = `${e.clientX - offset.x}px`;
      buttonRef.current.style.top = `${e.clientY - offset.y}px`;
    }
  };

  const stopDrag = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", onDrag);
      window.addEventListener("mouseup", stopDrag);
    } else {
      window.removeEventListener("mousemove", onDrag);
      window.removeEventListener("mouseup", stopDrag);
    }

    return () => {
      window.removeEventListener("mousemove", onDrag);
      window.removeEventListener("mouseup", stopDrag);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging, offset]);

  const handleOpenDialog = () => {
    const buttonRect = buttonRef.current.getBoundingClientRect();
    const dialogWidth = 300; // Example dialog width
    const dialogHeight = 300; // Example dialog height
    const gap = 10; // Fixed gap of 10px

    let top, left;

    // Position dialog above or below the button based on available space
    if (
      buttonRect.top + dialogHeight + buttonRect.height >
      window.innerHeight
    ) {
      top = buttonRect.top + window.scrollY - dialogHeight - gap;
    } else {
      top = buttonRect.top + window.scrollY + buttonRect.height + gap;
    }

    // Position dialog to the left or right of the button based on available space
    if (buttonRect.left + dialogWidth > window.innerWidth) {
      left = buttonRect.left + window.scrollX - dialogWidth + buttonRect.width;
    } else {
      left = buttonRect.left + window.scrollX;
    }

    setDialogPosition({ top, left });
    setDialogOpen(true);
    setIcon(<CloseIcon />); // Change icon to "X"
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setIcon(<Message />); // Reset icon to message
  };

  return (
    <>
      <button
        ref={buttonRef}
        style={{
          position: "fixed",
          bottom: "20px", // Adjusted for bottom-right positioning
          right: "20px", // Adjusted for bottom-right positioning
          backgroundColor: "#2c3d4e",
          color: "white",
          border: "none",
          borderRadius: "50%",
          width: "60px",
          height: "60px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          cursor: "pointer",
          zIndex: 10000,
        }}
        onMouseDown={startDrag}
        onClick={isDialogOpen ? handleCloseDialog : handleOpenDialog}
      >
        {icon}
        {unreadCount > 0 && (
          <div
            style={{
              position: "absolute",
              top: "-10px",
              right: "-10px",
              backgroundColor: "red",
              color: "white",
              borderRadius: "50%",
              width: "24px",
              height: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "14px", // Adjust font size here
              fontWeight: "bold",
              zIndex: 10001,
            }}
          >
            {unreadCount}
          </div>
        )}
      </button>

      {/* Chat Dialog */}
      <ChatDialog
        isDialogOpen={isDialogOpen}
        handleCloseDialog={handleCloseDialog}
        dialogPosition={dialogPosition}
        data={data}
        setDateRange={setDateRange}
      />
    </>
  );
};

export default FloatingButton;
