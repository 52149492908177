import React, { useState, useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Paper,
  List,
  ListItem,
  ListItemText,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { SendEmailReport } from "../../api";
import { AppContext } from "../../AppContext";
import { toast } from "react-toastify";

const SendEmailDialog = ({
  recipients,
  setRecipients,
  reportName,
  setReportName,
  body,
  setBody,
}) => {
  const [email, setEmail] = useState("");

  const handleAddRecipient = () => {
    if (email) {
      setRecipients([...recipients, email]);
      setEmail("");
    }
  };

  const handleDeleteRecipient = (index) => {
    setRecipients(recipients.filter((_, i) => i !== index));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          label="Report Name"
          size="small"
          value={reportName}
          onChange={(e) => setReportName(e.target.value)}
          fullWidth
          margin="normal"
        />
      </Grid>

      <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
        <TextField
          size="small"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
          type="email"
        />
        <IconButton
          onClick={handleAddRecipient}
          color="primary"
          style={{ marginLeft: 10 }}
        >
          <AddIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Paper style={{ padding: 10, maxHeight: 150, overflow: "auto" }}>
          <Typography variant="h6">Recipients</Typography>
          <List dense>
            {recipients.map((recipient, index) => (
              <ListItem
                key={index}
                secondaryAction={
                  <IconButton
                    edge="end"
                    onClick={() => handleDeleteRecipient(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemText primary={recipient} />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Email Body"
          value={body}
          onChange={(e) => setBody(e.target.value)}
          fullWidth
          multiline
          rows={4}
          margin="normal"
        />
      </Grid>
    </Grid>
  );
};

const EmailSender = ({ setOpen, open, pdfBlob }) => {
  const { context } = useContext(AppContext);
  const [recipients, setRecipients] = useState([]);
  const [reportName, setReportName] = useState(context.reporttitle);
  const [body, setBody] = useState("find attached");

  const handleClose = () => setOpen(false);
  const onSuccess = () => {
    setOpen(false);
  };

  console.log(context);

  const { loading, handleFormSubmit: handleUpload } = SendEmailReport(
    {
      emails: recipients,
      pdfBase64: pdfBlob,
      reportName: reportName,
      VendorLocationName: context?.VendorLocationName,
      TradingName: context.TradingName,
      Body: body,
    },
    [onSuccess]
  );

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Send Email</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Paper style={{ padding: 10 }}>
              <Typography variant="h6">PDF Preview</Typography>
              {pdfBlob ? (
                <iframe
                  src={`data:application/pdf;base64,${pdfBlob}`}
                  type="application/pdf"
                  width="100%"
                  height="600px"
                  title="PDF Viewer"
                />
              ) : (
                <Typography>No PDF available</Typography>
              )}
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <SendEmailDialog
              recipients={recipients}
              setRecipients={setRecipients}
              reportName={reportName}
              setReportName={setReportName}
              body={body}
              setBody={setBody}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        {loading ? (
          <CircularProgress />
        ) : (
          <Button
            onClick={() =>
              recipients.length > 0
                ? handleUpload()
                : toast.error("At least one recipient is required.")
            }
          >
            Share Report
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default EmailSender;
