import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { AppContext } from "../../AppContext";
import { encryptSession } from "../../api";
import moment from "moment";
import { DefaultContextDateRange } from "./Functions";

const SearchableDropdown = ({ locations, storedValues }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] = useState(
    storedValues || locations[0]
  );
  const { context, setContext, setVendorImage, setLocd } =
    useContext(AppContext);
  const [startTime, endTime] = DefaultContextDateRange("day");
  const dropdownRef = useRef(null);

  const filteredOptions = locations?.filter((option) =>
    option.VendorLocationName?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleOnChange = useCallback(
    (newValue) => {
      setContext((prevContext) => ({
        ...prevContext,
        ...newValue,
        VendorID: newValue.VendorID || encryptSession.getItem("ci"),
      }));
      setVendorImage(newValue.ImageID);
      localStorage.setItem("values", JSON.stringify(newValue));
      setLocd(newValue);
      encryptSession.setItem("cn", newValue.TradingName);
      encryptSession.setItem("cim", newValue.ImageID);
      encryptSession.setItem("locd", newValue.locd);
      encryptSession.setItem("ci", newValue.VendorID);
    },
    [setContext, setVendorImage, setLocd]
  );

  const handleDateChange = useCallback(
    (start, end) => {
      setContext((prevContext) => ({
        ...prevContext,
        DateRange: [start, end],
      }));
    },
    [setContext]
  );

  useEffect(() => {
    if (!context?.DateRange) {
      setContext((prevContext) => ({
        ...prevContext,
        DateRange: [startTime, endTime],
      }));
    }
  }, [context, setContext, startTime, endTime]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const renderDateInputs = () => {
    const startType = context?.timeRange === "time" ? "datetime-local" : "date";
    const endType = context?.timeRange === "time" ? "datetime-local" : "date";
    return (
      <>
        {context?.timeRange !== "endDate" && (
          <input
            type={startType}
            value={moment(
              new Date(context?.DateRange?.[0] || startTime)
            ).format(
              startType === "datetime-local" ? "YYYY-MM-DDTHH:mm" : "YYYY-MM-DD"
            )}
            onChange={(e) =>
              handleDateChange(
                e.target.value,
                context?.DateRange?.[1] || endTime
              )
            }
            placeholder="Start Date"
            className="form-control fc-datepicker"
            style={{
              width: "200px",
              borderRadius: "10px",
              padding: "8px",
              boxSizing: "border-box",
              marginRight: "10px",
            }}
          />
        )}
        <input
          type={endType}
          value={moment(new Date(context?.DateRange?.[1] || endTime)).format(
            endType === "datetime-local" ? "YYYY-MM-DDTHH:mm" : "YYYY-MM-DD"
          )}
          onChange={(e) =>
            handleDateChange(
              context?.DateRange?.[0] || startTime,
              e.target.value
            )
          }
          placeholder="End Date"
          className="form-control fc-datepicker"
          style={{
            width: "200px",
            padding: "8px",
            borderRadius: "10px",
            boxSizing: "border-box",
            marginRight: "10px",
          }}
        />
      </>
    );
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    handleOnChange(option);
    setIsVisible(false);
    setSearchTerm(""); // Reset the search term to reset the filtered list
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {context.timeRange === "nodate" ? null : renderDateInputs()}
      {context?.station !== "none" && (
        <div style={{ position: "relative", width: "200px" }} ref={dropdownRef}>
          <input
            type="text"
            value={selectedOption.VendorLocationName}
            className="form-control fc-datepicker"
            onClick={() => setIsVisible(!isVisible)}
            placeholder="Select an option"
            style={{
              width: "100%",
              padding: "8px",
              boxSizing: "border-box",
              borderRadius: "10px",
            }}
            readOnly
          />
          {isVisible && (
            <div
              style={{
                position: "absolute",
                width: "100%",
                border: "1px solid #ccc",
                zIndex: 1000,
                backgroundColor: "#FFF",
                boxSizing: "border-box",
                textAlign: "left",
              }}
            >
              <input
                type="text"
                className="form-control fc-datepicker"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{
                  width: "100%",
                  padding: "8px",
                  marginTop: "3px",
                  boxSizing: "border-box",
                }}
              />
              <ul
                style={{
                  listStyleType: "none",
                  padding: 0,
                  margin: "3px",
                }}
              >
                {filteredOptions.map((option) => (
                  <li
                    key={option.VendorLocationID}
                    onClick={() => handleOptionChange(option)}
                    style={{
                      padding: "8px",
                      cursor: "pointer",
                      backgroundColor: "#FFF",
                      color: "black",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      checked={
                        selectedOption.VendorLocationID ===
                        option.VendorLocationID
                      }
                      onChange={() => handleOptionChange(option)}
                      style={{ marginRight: "10px" }}
                    />
                    {option.VendorLocationName}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown;
