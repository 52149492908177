import React, { useContext, useState } from "react";
import Field from "./Field";
import useStyles from "./styles";
import styles from "./Login.module.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Paper, Grid, Typography, Container } from "@material-ui/core";

import ProgressButton from "react-progress-button";
import * as api from "../../api";
import { Box } from "@mui/material";
import { CheckCircle } from "@material-ui/icons";
import moment from "moment";
import { AppContext } from "../../AppContext";
// import Multiselect from "multiselect-react-dropdown";
const initialState = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  password: "",
  confirmPassword: "",
  profilePicture: "",
  bio: "",
};

var firstDay = new Date(new Date().valueOf() - 1000 * 60 * 60 * 164);
var lastDay = new Date();

const Login = () => {
  const classes = useStyles();
  const { context, setContext } = useContext(AppContext);
  const [formData, setFormData] = useState(initialState);
  const [showPassword, setShowPassword] = useState(false);
  const [buttonState, setButtonState] = useState("");
  const tpy = +localStorage.getItem("tpy") === 0 ? "customer" : "vendor";
  const [type, setType] = useState(tpy || "vendor");
  const handleShowPassword = () => setShowPassword(!showPassword);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  function SetDefaultRange(locations) {
    api.encryptSession.setItem("cn", locations[0].TradingName);
    api.encryptSession.setItem("sl", locations[0].Slogan);
    api.encryptSession.setItem("cim", locations[0].ImageID);
    api.encryptSession.setItem("locd", locations[0]);
    setContext({
      ...context,
      VendorName: locations[0]?.TradingName,
      VendorImage: locations[0]?.ImageID,
      VendorLocationID: locations[0]?.VendorLocationID,
      VendorID: locations[0]?.VendorID,
      VendorStatusID: locations[0]?.VendorStatusIDF,
    });
    localStorage.setItem(
      "values",
      JSON.stringify({
        VendorLocationID: locations[0].VendorLocationID,
        VendorLocationName: locations[0].VendorLocationName,
        DateRange: [firstDay, lastDay],
        startDate: moment(firstDay).format("YYYY-MM-DD"),
        endDate: moment(lastDay).format("YYYY-MM-DD"),
      })
    );
  }

  const handleSubmit = (e) => {
    setButtonState("loading");
    e.preventDefault();
    if (formData.email !== "" && formData.password !== "") {
      if (type === "customer") {
        api
          .UserLoginCustomer(formData)
          .then((res) => res.json())
          .then((data) => {
            if (data.UserID !== 0 && data.ProviderID !== 0) {
              api.encryptSession.setItem("tk", data.UserToken);
              api.encryptSession.setItem("us", data.UserID);
              api.encryptSession.setItem("cn", data.CustomerName);
              api.encryptSession.setItem("vcn", data.CustomerName);
              api.encryptSession.setItem("cid", data.CustomerID);
              api.encryptSession.setItem(
                "loc",
                JSON.stringify([
                  {
                    VendorLocationID: data?.VendorLocationID,
                    StatusID: 1,
                    TradingName: data.TradingName,
                    ContactEmail: data.ContactEmail,
                    ImageID: data.ImageID,
                    VendorLocationName: data.VendorLocationName,
                    VendorLocationPhone: data.VendorLocationPhone,
                  },
                ])
              );
              SetDefaultRange([
                {
                  VendorLocationID: data.VendorLocationID,
                  StatusID: 1,
                  TradingName: data.TradingName,
                  ContactEmail: data.ContactEmail,
                  ImageID: data.ImageID,
                  VendorLocationName: data.VendorLocationName,
                  VendorLocationPhone: data.VendorLocationPhone,
                },
              ]);
              api.encryptSession.setItem("ust", data.UserTypeID);
              // api.encryptSession.setItem("cim", data.UserImageID);
              api.encryptSession.setItem("usn", data.FirstName);
              api.encryptSession.setItem("phn", data.PhoneNumber);
              api.encryptSession.setItem("u", data);
              localStorage.setItem("tpy", 0);
              localStorage.setItem("index", 0);
              window.location.href = "/customer/dashboard";
              toast.success(`${data.Message} 👌`);
              setButtonState("success");
            }
            if (data.UserID === 0) {
              setButtonState("error");
              toast.error(`Error, ${data.Message} 😲`);
            }
            if (data.UserID !== 0 && data.ProviderID === 0) {
              setTimeout(() => {
                setButtonState("error");
                toast.error(
                  `No company assigned please create a business profile 😲`
                );
              }, 3000);
            }
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              setTimeout(() => {
                setButtonState("error");
                toast.error(`Invalid email or password" 😲`);
              }, 3000);
            }
          });
      }
      if (type === "vendor") {
        api
          .UserLogin(formData)
          .then((res) => res.json())
          .then((data) => {
            if (data.UserID !== 0 && data.ProviderID !== 0) {
              localStorage.setItem("profile", data.UserID);
              api.encryptSession.setItem("tk", data.UserToken);
              api.encryptSession.setItem("us", data.UserID);
              api.encryptSession.setItem("ci", data.VendorID);
              api.encryptSession.setItem("pi", data.VendorParentID);
              api.encryptSession.setItem("ust", data.UserTypeID);
              // api.encryptSession.setItem("cim", data.VendorImageID);
              api.encryptSession.setItem("usn", data.FirstName);
              api.encryptSession.setItem("phn", data.PhoneNumber);
              api.encryptSession.setItem("loc", data.AssignedStationArray);
              SetDefaultRange(data.AssignedStationArray);
              api.encryptSession.setItem("u", data);
              api.encryptSession.setItem("type", data.VendorTypeID);
              localStorage.setItem("tpy", 1);
              localStorage.setItem("index", 0);
              window.location.href =
                data.VendorTypeID === 4
                  ? "/loyalty/dashboard"
                  : "/dashboard/dashboard";
              toast.success(`${data.Message} 👌`);
              setButtonState("success");
            }
            if (data.UserID === 0) {
              setButtonState("error");
              toast.error(`Error, ${data.Message} 😲`);
            }
            if (data.UserID !== 0 && data.ProviderID === 0) {
              setTimeout(() => {
                setButtonState("error");
                toast.error(
                  `No company assigned please create a business profile 😲`
                );
              }, 3000);
            }
            setContext({
              ...context,
              VendorName: data.AssignedStationArray[0]?.TradingName,
              VendorImage: data.AssignedStationArray[0]?.ImageID,
              VendorLocationID: data.AssignedStationArray[0]?.VendorLocationID,
              VendorStatusID: data.AssignedStationArray[0]?.VendorStatusID,
            });
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              setTimeout(() => {
                setButtonState("error");
                toast.error(`Invalid email or password" 😲`);
              }, 3000);
            }
          });
      }
    } else {
      setTimeout(() => {
        setButtonState("error");
      }, 1000);
      toast.error(`Please fill in the credentials" 😲`);
    }

    setTimeout(() => {
      setButtonState("error");
    }, 15000);
  };

  const switchMode = () => {
    window.location.href = "/signup";
  };

  // if (user) {
  //   history.push("/dashboard");
  // }

  return (
    <Grid container style={{ height: "100%" }}>
      <Grid
        item
        sm={8}
        style={{
          backgroundImage: `url(https://i.postimg.cc/sDG8zyXM/wave.png)`,
          backgroundSize: "100% 100%",
        }}
      >
        <Box display="flex" justifyContent="flex-end">
          <img src="https://svgshare.com/i/JcM.svg" alt="" width={"80%"} />
        </Box>
      </Grid>
      <Grid item sm={3}>
        <Container component="main" maxWidth="sm">
          <Paper className={classes.paper}>
            <img src="https://svgshare.com/i/Jcf.svg" alt="" width={90} />
            <Typography component="h1" variant="h5">
              WELCOME
            </Typography>
            <form>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    onClick={() => setType("vendor")}
                    startIcon={type === "vendor" && <CheckCircle />}
                    style={{
                      border: type === "vendor" && "2px solid purple",
                      color: type === "vendor" && "purple",
                      // backgroundColor: type === "vendor" && "gainsboro",
                    }}
                  >
                    AS STATION
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => setType("customer")}
                    startIcon={type === "customer" && <CheckCircle />}
                    style={{
                      border: type === "customer" && "2px solid purple",
                      color: type === "customer" && "purple",
                      // backgroundColor: type === "vendor" && "gainsboro",
                    }}
                  >
                    AS CUSTOMER
                  </Button>
                </Grid>
              </Grid>
            </form>

            <form className={classes.form} onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <>
                  <Field
                    name="email"
                    label="Phone / EMail"
                    handleChange={handleChange}
                    type="text"
                  />

                  <Field
                    name="password"
                    label="Password"
                    handleChange={handleChange}
                    type={showPassword ? "text" : "password"}
                    handleShowPassword={handleShowPassword}
                  />
                </>
              </Grid>

              <div className={styles.buttons}>
                <div className="loginbutton">
                  <ProgressButton style={{ width: 200 }} state={buttonState}>
                    Sign In
                  </ProgressButton>
                </div>
              </div>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Button onClick={switchMode}>
                    Don't have an account? Sign Up
                  </Button>
                </Grid>
              </Grid>
              <Link to="forgot">
                <p
                  style={{
                    textAlign: "center",
                    color: "#1d7dd6",
                    marginTop: "20px",
                  }}
                >
                  Forgotten Password?
                </p>
              </Link>
            </form>
          </Paper>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Login;
