import React, { useState, useEffect, useRef, useContext, useLayoutEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  TextField,
  InputAdornment,
  IconButton,
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import AttachFile from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import CheckIcon from "@mui/icons-material/Check";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { isToday, subMonths } from "date-fns";
import { AddCratoChat, encryptSession, UpdateCratoChatStatus } from "./api";
import { AppContext } from "./AppContext";

const ChatDialog = ({
  isDialogOpen,
  handleCloseDialog,
  data,
  setDateRange,
}) => {
  const { context } = useContext(AppContext);
  const [newChat, setNewChat] = useState("");
  const [chatList, setChatList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileBase64, setFileBase64] = useState("");
  const [updatelist, setUpdatelist] = useState([]);

  const SenderID = +encryptSession.getItem("us");

  useEffect(() => {
    setChatList(data);
  }, [data]);

  const onSuccess = () => {};

  useEffect(() => {
    if (data) {
      const filteredChats = data
        .filter((chat) => chat.StatusID === 1 && chat.SenderID !== SenderID)
        ?.map((l) => {
          return { ...l, StatusID: 2 };
        });
      setUpdatelist(filteredChats);
    }
    if (isDialogOpen && updatelist.length > 0) {
      handleUpdateStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const { loading, handleFormSubmit: handleonClick } = AddCratoChat(
    {
      VendorLocationID: context.VendorLocationID,
      Messange: newChat,
      MessangeFile: fileBase64,
    },
    [onSuccess]
  );

  const { handleFormSubmit: handleUpdateStatus } = UpdateCratoChatStatus(
    updatelist,
    [onSuccess]
  );

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    if (isToday(date)) {
      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    } else {
      return `${date.toLocaleDateString([], {
        month: "short",
        day: "numeric",
        year: "numeric",
      })} ${date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })}`;
    }
  };

  const chatListRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);

      // Read the file as a Data URL
      const reader = new FileReader();
      reader.onloadend = () => {
        // Extract base64 string with MIME type
        const base64String = reader.result.split(",")[1];
        setFileBase64(base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddChat = () => {
    if (newChat.trim() !== "" || selectedFile) {
      const newChatItem = {
        SenderID,
        MessageText: newChat,
        SentAt: new Date().toISOString(),
        StatusID: 0,
        MediaUrl: selectedFile
          ? `data:${selectedFile.type};base64,${fileBase64}`
          : null,
      };
      handleonClick();
      setChatList((prevChatList) => [...prevChatList, newChatItem]);
      setNewChat("");
      setSelectedFile(null);
      setFileBase64("");
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 2:
        return (
          <CheckCircleIcon fontSize="small" style={{ color: "#4CAF50" }} />
        );
      case 1:
        return <CheckIcon fontSize="small" style={{ color: "#4CAF50" }} />;
      case 0:
        return (
          <RadioButtonUncheckedIcon
            fontSize="small"
            style={{ color: "#888" }}
          />
        );
      default:
        return null;
    }
  };

  const handleExpandDateRange = () => {
    setDateRange((prevRange) => ({
      ...prevRange,
      start: subMonths(prevRange.start, 1), // Decrease start date by 1 month
    }));
  };

  useEffect(() => {
    if (chatListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatListRef.current;
      const isNearBottom = scrollTop + clientHeight >= scrollHeight - 50;
  
      if (isNearBottom) {
        chatListRef.current.scrollTop = scrollHeight;
      }
    }
  }, [chatList]);
  
  useEffect(() => {
    const images = chatListRef.current?.querySelectorAll('img');
  
    if (images) {
      const handleImageLoad = () => {
        chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
      };
  
      images.forEach((img) => {
        if (img.complete) {
          handleImageLoad();
        } else {
          img.addEventListener('load', handleImageLoad);
        }
      });
  
      return () => {
        images.forEach((img) => {
          img.removeEventListener('load', handleImageLoad);
        });
      };
    }
  }, [chatList]);
  
  

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleCloseDialog}
      PaperProps={{
        style: {
          position: "fixed",
          top: 0,
          right: 0,
          height: "100vh",
          width: "350px",
          margin: 0,
          transform: "none",
          display: "flex",
          flexDirection: "column",
        },
      }}
      fullWidth
    >
      <DialogTitle
        style={{
          backgroundColor: "#2c3d4e",
          color: "#44dc94",
          padding: "16px",
          display: "flex",
          flexDirection: "column", // Change to column for vertical alignment
          justifyContent: "center", // Center content vertically
          alignItems: "center", // Center content horizontally
          borderBottom: "1px solid #44dc94",
        }}
      >
        <Box>
          <Typography
            variant="h6"
            style={{ marginBottom: "4px", textAlign: "center" }}
          >
            Chat with us 😊
          </Typography>
          <Typography
            variant="body2"
            style={{
              fontSize: "0.75rem",
              textAlign: "center",
              marginTop: "4px",
              color: "#d0d0d0",
            }}
          >
            available everyday 05:00hrs to 22:00hrs
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent style={{ flex: 1, overflowY: "auto", padding: "16px" }}>
        {chatList.length === 0 ? (
          <Typography
            variant="body2"
            style={{
              textAlign: "center",
              color: "#888",
              fontStyle: "italic",
              marginTop: "20px",
            }}
          >
            No messages yet. Start the conversation!
          </Typography>
        ) : (
          <List ref={chatListRef}>
            <Button
              variant="outlined"
              size="small"
              style={{
                color: "#44dc94",
                borderColor: "#44dc94",
                marginBottom: "8px",
              }}
              onClick={handleExpandDateRange}
            >
              more chats
            </Button>
            {chatList?.map((chat, index) => (
              <ListItem
                key={index}
                style={{
                  justifyContent:
                    chat.SenderID === SenderID ? "flex-end" : "flex-start",
                  marginBottom: "4px", // Reduced space between messages
                }}
              >
                <Box
                  sx={{
                    backgroundColor:
                      chat.SenderID === SenderID ? "#DCF8C6" : "#FFF",
                    padding: "10px", // Reduced padding for a more compact look
                    borderRadius: "10px",
                    maxWidth: "75%", // Slightly narrower for better fit
                    textAlign: chat.SenderID === SenderID ? "right" : "left",
                    position: "relative",
                    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Lighter shadow
                    fontSize: "0.85rem",
                  }}
                >
                  {chat.SenderID !== SenderID && (
                    <div
                      style={{
                        fontStyle: "italic",
                        fontWeight: "bold",
                        fontSize: "small",
                      }}
                    >
                      {chat.SenderName?.split(" ")[0]}
                    </div>
                  )}

                  {chat.MediaUrl ? (
                    <>
                      <img
                        src={
                          chat.StatusID === 0
                            ? chat.MediaUrl
                            : `https://api.crato.africa/api/File/CratoAdminChatMessageFile?ImageID=${chat.MediaUrl?.replace(
                                ".png",
                                ""
                              )}`
                        }
                        loading="lazy"
                        alt="File Preview"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "200px",
                          borderRadius: "10px",
                        }}
                      />
                      <Typography variant="body2">
                        {chat.MessageText}
                      </Typography>
                    </>
                  ) : (
                    <Typography variant="body2">{chat.MessageText}</Typography>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      marginTop: "4px",
                      fontSize: "0.75rem",
                      color: "#888",
                    }}
                  >
                    <Typography variant="caption">
                      {formatTimestamp(new Date(chat.SentAt))}
                    </Typography>
                    {chat.SenderID === SenderID && (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {getStatusIcon(chat.StatusID)}
                      </Box>
                    )}
                  </Box>
                </Box>
              </ListItem>
            ))}
          </List>
        )}
        {selectedFile && !newChat && (
          <ListItem style={{ marginTop: "8px" }}>
            <Box
              sx={{
                padding: "10px",
                borderRadius: "10px",
                backgroundColor: "#f5f5f5",
                textAlign: "center",
                boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Lighter shadow
              }}
            >
              <Typography variant="body2" sx={{ marginBottom: "10px" }}>
                File Preview:
              </Typography>
              <img
                src={URL.createObjectURL(selectedFile)}
                alt="File Preview"
                style={{
                  maxWidth: "100%",
                  maxHeight: "200px",
                  borderRadius: "10px",
                }}
              />
            </Box>
          </ListItem>
        )}
      </DialogContent>

      <DialogActions
        sx={{
          padding: "8px 16px",
          borderTop: "1px solid #44dc94",
        }}
      >
        <TextField
          variant="outlined"
          placeholder="Type a message..."
          value={newChat}
          onChange={(e) => setNewChat(e.target.value)}
          sx={{
            flex: 1,
            backgroundColor: "#f9f9f9",
            borderRadius: "20px",
            fontSize: "0.85rem",
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  aria-label="attach file"
                  component="label"
                  size="small"
                  sx={{
                    backgroundColor: "#fff",
                    padding: "6px",
                    borderRadius: "50%",
                  }}
                >
                  <AttachFile sx={{ fontSize: "20px" }} />
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {loading ? (
                  <CircularProgress />
                ) : (
                  <IconButton
                    onClick={handleAddChat}
                    disabled={newChat.trim() === "" && !selectedFile}
                    sx={{
                      backgroundColor: "#44dc94",
                      padding: "6px",
                      borderRadius: "50%",
                    }}
                  >
                    <SendIcon sx={{ fontSize: "20px", color: "#fff" }} />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ChatDialog;
